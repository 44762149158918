<!-- 自定义标签组件 -->
<template>
    <div class="zx-tag" :class="[`zx-tag__${size}`]"
         :style="{'--color': color, '--bgcolor': bgColor, '--borcolor': borColor}">
        <!-- <view class="zx-tag" > -->
        <slot>标签</slot>
    </div>
</template>

<script>
export default {
    props: {
        // 标签大小
        size: {
            type: String, //large medium / small / mini
            default: 'small'
        },
        // 色值
        color: {
            type: String,
            default: '#1890ff'
        }
    },
    data() {
        return {}
    },
    computed: {
        bgColor() {
            return this.colorRgb(this.color, 0.15)
        },
        borColor() {
            return this.colorRgb(this.color, 0.3)
        },
    },
    methods: {
        changeColor(val) {
        },
        // 颜色值转换成rgba
        colorRgb(color, a) {
            var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
            var sColor = color.toLowerCase()
            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    var sColorNew = '#'
                    for (var i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
                    }
                    sColor = sColorNew
                }
                //处理六位的颜色值
                var sColorChange = []
                for (var i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
                }
                return `rgba(${sColorChange.join(',')},${a})`
            } else {
                return sColor
            }
        },
    }
}
</script>

<style scoped lang="scss">
.zx-tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid var(--borcolor);
  color: var(--color);
  background: var(--bgcolor);

  &__large {
    font-size: 14px;
    padding: 8px;
  }

  &__medium {
    padding: 5px 8px;
  }

  &__small {
    padding: 3px 8px;
  }

  &__mini {
    padding: 2px 6px;
  }
}
</style>
